<template>
<div>
  <div v-b-toggle="collapseId" class="filter-check-container d-flex justify-content-between mb-2 text-l">
    <div class="filter-check-title">{{ title }}</div>
    <div class="collapse-control">
      <img src="@/components/FilterCheck/collapse-icon.svg">
    </div>
  </div>
  <b-collapse visible :id="collapseId">
    <b-form-group class="mb-2">
      <b-form-checkbox-group v-model="checkedOptions">
          <b-form-checkbox
            class="avify-checkbox filter w-100"
            :value="option"
            v-for="option in options.slice(0, this.numberOptions)"
            :key="option.id"
          >
          <div class="result d-flex justify-content-between">
            <div>{{ option.name }}</div>
            <div v-if="false">{{ option.total }}</div>
          </div>
        </b-form-checkbox>
      </b-form-checkbox-group>
    </b-form-group>
    <b-link @click="onClickOpenViewMore" v-if="modalShowMore && viewMoreNumber > 0">Ver más ({{ viewMoreNumber }})</b-link>
  </b-collapse>
  <filter-check-modal
    v-if="modalShowMore"
    :modal-id="modalId"
    :title="modalTitle"
    :description-title="modalDescriptionTitle"
    :description="modalDescription"
    :options="orderedOptions"
    :checkedOptions="checkedOptions"
    @onChangeViewMoreData="onChangeViewMoreData"
  >
  </filter-check-modal>
</div>
</template>

<script>
import FilterCheckModal from '../Modal/FilterCheckModal'
export default {
  name: 'FilterCheck',
  components: { FilterCheckModal },
  props: {
    title: {
      type: String,
      required: true
    },
    modalTitle: {
      type: String,
      default: ''
    },
    modalDescriptionTitle: {
      type: String
    },
    modalDescription: {
      type: String
    },
    modalShowMore: {
      type: Boolean,
      default: true
    },
    options: {
      type: Array,
      required: true
    },
    eventName: {
      type: String
    },
    selectedOptions: {
      type: Array
    },
    numberOptions: {
      type: Number,
      required: false,
      default: 4
    }
  },
  computed: {
    orderedOptions () {
      const orderedOptions = this.options.slice()
      orderedOptions.sort((a, b) => a.name.localeCompare(b.name))

      return orderedOptions
    },
    collapseId () {
      return 'filter-check-collapse-' + this.modalTitle.replace(/\s/g, '-').toLowerCase()
    },
    modalId () {
      return 'filter-check-modal-' + this.modalTitle.replace(/\s/g, '-').toLowerCase()
    },
    checkedOptions: {
      get () {
        return this.selectedOptions
      },
      set (newValue) {
        this.$emit(this.eventName, newValue)
      }
    },
    viewMoreNumber () {
      return this.options.length - this.numberOptions
    }
  },
  methods: {
    onClickOpenViewMore () {
      this.$bvModal.show(this.modalId)
    },
    onChangeViewMoreData (selected) {
      this.$emit(this.eventName, selected)
    }
  }
}
</script>

<style lang="less">
  @import 'filterCheck.less';
</style>
