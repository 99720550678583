<template>
<div class="mb-3">
  <div v-b-toggle="collapseId" class="filter-check-container d-flex justify-content-between mb-2 text-l">
    <div class="filter-check-title">{{ title }}</div>
    <div class="collapse-control">
      <img src="@/components/FilterCheck/collapse-icon.svg">
    </div>
  </div>
  <b-collapse visible :id="collapseId">
    <b-form-select v-model="checkedOption" :options="options"></b-form-select>
  </b-collapse>
  <span class="text-sm text-pink" v-if="this.showWarningInfo">{{ warningInfoText }}</span>
</div>
</template>

<script>
export default {
  name: 'FilterSelect',
  props: {
    title: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    eventName: {
      type: String
    },
    selectedOption: {
      type: Number
    },
    showWarningInfo: {
      type: Boolean,
      default: false
    },
    warningInfoText: {
      type: String,
      default: ''
    }
  },
  computed: {
    collapseId () {
      return 'filter-select-collapse-' + this.title.replace(/\s/g, '-').toLowerCase()
    },
    checkedOption: {
      get () {
        return this.selectedOption
      },
      set (newValue) {
        this.$emit(this.eventName, newValue)
      }
    }
  }
}
</script>

<style lang="less">
  @import 'filterSelect.less';
</style>
