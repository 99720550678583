<template>
  <modal-component
    :id="modalId"
    :title="title"
    size="lg"
    ok-title="Aplicar"
    @confirmed="onApplyFilters"
    :confirmation-close="false"
    :scrollable="true"
  >
    <div class="filter-check-description-title mb-1">{{ descriptionTitle }}</div>
    <div class="filter-check-description-text mb-4">{{ description }}</div>
    <b-row>
      <b-col cols="12">
        <b-form-group class="mb-2">
          <b-form-checkbox-group v-model="filtersData">
            <b-row>
              <b-col
                cols="12"
                lg="6"
                v-for="option in options"
                :key="option.id"
              >
                <b-form-checkbox
                  class="avify-checkbox filter"
                  :value="option"
                >
                  <div class="result d-flex justify-content-between">
                    <div>{{ option.name }}</div>
                    <div v-if="false">{{ option.total }}</div>
                  </div>
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-form-checkbox-group>
        </b-form-group>
      </b-col>
    </b-row>
  </modal-component>
</template>

<script>
import ModalComponent from './ModalComponent'
export default {
  name: 'FilterCheckModal',
  components: { ModalComponent },
  props: {
    modalId: {
      type: String
    },
    title: {
      type: String
    },
    descriptionTitle: {
      type: String
    },
    description: {
      type: String
    },
    options: {
      type: Array,
      required: true
    },
    checkedOptions: {
      type: Array
    }
  },
  data () {
    return {
      selectedFiltersData: []
    }
  },
  computed: {
    filtersData: {
      get () {
        return Array.from(this.checkedOptions)
      },
      set (newValue) {
        this.selectedFiltersData = Array.from(newValue)
      }
    }
  },
  methods: {
    onApplyFilters (modalId) {
      this.$nextTick(() => {
        this.$bvModal.hide(modalId)
      })
      this.$emit('onChangeViewMoreData', this.selectedFiltersData)
    }
  }
}
</script>

<style lang="less">
  @import "filterCheckModal";
</style>
