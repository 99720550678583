<template>
  <div>
    <b-card
      class="avify-user-card mb-4"
      @click="onCLickInCard"
      no-body
    >
      <b-card-header>
        <div
          class="card-header-container d-flex flex-column justify-content-end"
          :style="[!searchUserItem.hasAvatar ? {'background-image': 'url(/img/default-user-avatar-card.svg)'} : {'background-image': 'url('+ completeUserAvatarPath + ')'}]"
        >
          <div class="card-header-info-container">
            <div class="card-header-info">
              <div class="card-header-name text-l">
                <span v-if="searchUserItem.hasCompleteName">
                  {{ searchUserItem.getCompleteName }}
                </span>
              </div>
              <div class="card-header-location text-sm d-flex align-baseline" >
                <img
                  src="../../assets/icons/icon-location.svg"
                  class="mr-2" v-if="searchUserItem.hasLocation"/>
                <span v-if="searchUserItem.hasLocation">
                  {{ searchUserItem.getCompleteLocation }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </b-card-header>
      <b-card-body class="d-flex flex-column">
        <div class="user-filter-card-roles ">
          <div
            class="user-filter-card-role-info mb-2 d-flex flex-column justify-content-start"
            v-for="role in searchUserItem.getRoles.slice(0, 3)"
            :key="role.id"
          >
            {{ role.name }}
          </div>
        </div>
        <div class="user-filter-card-role-info view-more d-flex flex-column justify-content-end">Ver más</div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'UserFilterCard',
  props: {
    searchUserItem: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      baseUrl: process.env.VUE_APP_IMAGE_API_URL
    }
  },
  computed: {
    completeUserAvatarPath () {
      return this.baseUrl + this.searchUserItem.avatar.path
    }
  },
  methods: {
    onCLickInCard () {
      this.$router.push({ name: 'private-technician-profile', params: { id: this.searchUserItem.id } })
    }
  }
}
</script>

<style lang="less">
 @import "userFilterCard";
</style>
