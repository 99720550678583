export default class getProvincesListUtil {
  static getSpainProvinces () {
    return [
      { id: '28', name: 'Madrid', latitude: '40.4167', longitude: '-3.70358' },
      { id: '8', name: 'Barcelona', latitude: '41.3829', longitude: '2.17743' },
      { id: '46', name: 'Valencia/València', latitude: '39.4699', longitude: '-0.376' },
      { id: '48', name: 'Bizkaia', latitude: '43.2535', longitude: '-2.93693' },
      { id: '2', name: 'Albacete', latitude: '38.9951', longitude: '-1.85596' },
      { id: '3', name: 'Alicante/Alacant', latitude: '38.3537', longitude: '-0.490185' },
      { id: '4', name: 'Almería', latitude: '36.8414', longitude: '-2.46281' },
      { id: '1', name: 'Araba/Álava', latitude: '42.8465', longitude: '-2.6724' },
      { id: '33', name: 'Asturias', latitude: '43.545', longitude: '-5.66264' },
      { id: '5', name: 'Ávila', latitude: '40.6565', longitude: '-4.70022' },
      { id: '6', name: 'Badajoz', latitude: '38.8779', longitude: '-6.97061' },
      { id: '7', name: 'Balears, Illes', latitude: '39.5696', longitude: '2.65007' },
      { id: '9', name: 'Burgos', latitude: '42.3439', longitude: '-3.69698' },
      { id: '10', name: 'Cáceres', latitude: '39.4765', longitude: '-6.37224' },
      { id: '11', name: 'Cádiz', latitude: '36.5298', longitude: '-6.29257' },
      { id: '39', name: 'Cantabria', latitude: '43.462', longitude: '-3.80997' },
      { id: '12', name: 'Castellón/Castelló', latitude: '39.986', longitude: '-0.0377354' },
      { id: '13', name: 'Ciudad Real', latitude: '38.9854', longitude: '-3.92851' },
      { id: '14', name: 'Córdoba', latitude: '37.8846', longitude: '-4.77601' },
      { id: '15', name: 'Coruña, A', latitude: '43.3712', longitude: '-8.39588' },
      { id: '16', name: 'Cuenca', latitude: '40.0681', longitude: '-2.13482' },
      { id: '20', name: 'Gipuzkoa', latitude: '43.2697', longitude: '-2.94837' },
      { id: '17', name: 'Girona', latitude: '41.9793', longitude: '2.81994' },
      { id: '18', name: 'Granada', latitude: '37.1831', longitude: '-3.60219' },
      { id: '19', name: 'Guadalajara', latitude: '40.6327', longitude: '-3.16461' },
      { id: '21', name: 'Huelva', latitude: '37.2576', longitude: '-6.94849' },
      { id: '22', name: 'Huesca', latitude: '42.1383', longitude: '-0.40809' },
      { id: '23', name: 'Jaén', latitude: '37.7729', longitude: ' -3.78833' },
      { id: '24', name: 'León', latitude: '42.6', longitude: '-5.57032' },
      { id: '25', name: 'Lleida', latitude: '41.6148', longitude: '0.626784' },
      { id: '27', name: 'Lugo', latitude: '43.0118', longitude: '-7.55657' },
      { id: '29', name: 'Málaga', latitude: '36.7213', longitude: '-4.42164' },
      { id: '30', name: 'Murcia', latitude: '37.9924', longitude: '-1.13054' },
      { id: '31', name: 'Navarra', latitude: '42.8185', longitude: '-1.64426' },
      { id: '32', name: 'Ourense', latitude: '42.3436', longitude: '-7.86742' },
      { id: '34', name: 'Palencia', latitude: '42.0109', longitude: '-4.53287' },
      { id: '35', name: 'Palmas, Las', latitude: '28.1289', longitude: '-15.4349' },
      { id: '36', name: 'Pontevedra', latitude: '42.4319', longitude: '-8.64316' },
      { id: '26', name: 'Rioja, La', latitude: '42.4661', longitude: '-2.43967' },
      { id: '37', name: 'Salamanca', latitude: '40.9652', longitude: '-5.66402' },
      { id: '38', name: 'Santa Cruz de Tenerife', latitude: '28.4696', longitude: '-16.2541' },
      { id: '40', name: 'Segovia', latitude: '40.9502', longitude: '-4.12415' },
      { id: '41', name: 'Sevilla', latitude: '37.3886', longitude: '-5.99534' },
      { id: '42', name: 'Soria', latitude: '41.7634', longitude: '-2.4642' },
      { id: '43', name: 'Tarragona', latitude: '41.1172', longitude: '1.25461' },
      { id: '44', name: 'Teruel', latitude: '40.3437', longitude: '-1.10819' },
      { id: '45', name: 'Toledo', latitude: '39.856', longitude: '-4.02396' },
      { id: '47', name: 'Valladolid', latitude: '41.6521', longitude: '-4.72856' },
      { id: '49', name: 'Zamora', latitude: '41.5057', longitude: '-5.7448' },
      { id: '50', name: 'Zaragoza', latitude: '41.6521', longitude: '-0.880943' },
      { id: '51', name: 'Ceuta', latitude: '35.8884', longitude: '-5.30414' },
      { id: '52', name: 'Melilla', latitude: '35.2919', longitude: '-2.94071' }
    ]
  }
}
