<template>
  <div id="finder">
    <Header
      :user="loggedUser"
      :currentRouteName="$route.name"
    />
    <b-container fluid="sm" class="finder-container" v-if="!this.loading">
      <b-row class="d-block d-sm-none mb-3">
        <b-col cols="12">
          <a href="javascript:void(0)" class="btn btn-pink w-100" @click="showFilters">Desplegar buscador</a>
        </b-col>
      </b-row>
      <b-row class="d-block d-sm-none">
        <b-col cols="12" class="float">
          <a href="javascript:void(0)" class="btn btn-primary d-flex justify-content-center align-items-center w-100" @click="onClickShowFiltersResults">{{ mobileFiltersButtonText }}</a>
        </b-col>
      </b-row>
      <b-row class="d-sm-flex" :class="{'d-none': !showMobileFilters }">
        <b-col cols="12" lg="3" class="pr-sm-0 mb-2 mb-sm-0">
          <input type="text" class="input-finder name w-100" placeholder="Nombre o apodo" v-model="name" @keyup="onChangeName">
        </b-col>
        <b-col cols="12" lg="6" class="pr-sm-0 mb-2 mb-sm-0">
          <v-select
            :options="allSkills"
            class="v-select-finder"
            v-model="skills"
            :value="skills"
            :clearable="false"
            :selectOnTab="true"
            placeholder="Habilidades, roles"
            @input="onChangeFilterSkills"
            :filterBy="filterSkills"
          >
          </v-select>
        </b-col>
        <b-col cols="12" lg="3">
          <GooglePlaces
            ref="googlePlaces"
            class="input-finder"
            v-model="location"
            @selected="onChangeGoogleData"
          />
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col cols="12" sm="12" md="6" lg="3" class="filter-col d-sm-block mb-0 mb-sm-5" :class="{'d-none': !showFiltersResults }">
          <div class="d-flex align-items-baseline justify-content-between">
            <p class="d-none d-sm-block"> Inicio | Buscador</p>
          </div>
          <div class="filter-title d-flex align-items-baseline justify-content-between">
            <h3>Filtros</h3>
            <span>
              <b-link class="text-md pull-right" @click="onClickResetFilters">
                Reiniciar
              </b-link>
            </span>
          </div>
          <hr>
          <filter-check
            v-if="skillsOptions"
            class="mb-3"
            title="Habilidades"
            modal-title="Todas las habilidades"
            modal-description-title="Habilidades"
            modal-description="En este listado encontrarás tanto softwares como hardware. Podrás elegir uno o varios antes de realizar una búsqueda."
            :options="skillsOptions"
            :selected-options="skillsCheckedOptions"
            event-name="changeSkill"
            @changeSkill="onChangeSkillOptions"
          ></filter-check>
          <filter-check
            v-if="rolesOptions"
            title="Roles"
            class="mb-3"
            modal-title="Todos los roles"
            modal-description-title="Roles"
            modal-description="En este listado encontrarás todos los trabajos que actualmente tenemos en nuestra base de datos. Podrás elegir uno o varios antes de realizar una búsqueda."
            :options="rolesOptions"
            :selected-options="rolesCheckedOptions"
            event-name="changeRoles"
            @changeRoles="onChangeRoles"
          ></filter-check>
          <filter-check
            v-if="sectorsOptions"
            class="mb-3"
            title="Experiencia en sectores"
            modal-title="Todos las experiencias en sectores"
            modal-description-title="Experiencia en sectores"
            modal-description="En este listado encontrarás todas los trabajos que actualmente tenemos en nuestra base de datos. Podrás elegir uno o varios antes de realizar una búsqueda."
            :options="sectorsOptions"
            :selected-options="sectorsCheckedOptions"
            event-name="changeSectors"
            @changeSectors="onChangeSectors"
          ></filter-check>
          <filter-check
            v-if="languagesOptions"
            title="Idiomas"
            class="mb-3"
            modal-title="Todos los idiomas"
            modal-description-title="Idiomas"
            modal-description="En este listado encontrarás todos los trabajos que actualmente tenemos en nuestra base de datos. Podrás elegir uno o varios antes de realizar una búsqueda."
            :options="languagesOptions"
            :selected-options="languagesCheckedOptions"
            event-name="changeLanguages"
            @changeLanguages="onChangeLanguages"
          ></filter-check>
          <filter-check
            title="Localización"
            :modal-show-more="true"
            class="mb-3"
            modal-title="Todas las provincias"
            modal-description-title="Provincias"
            modal-description="En este listado encontrarás todas las provincias que actualmente tenemos en nuestra base de datos. Podrás elegir una o varias antes de realizar una búsqueda."
            :options="locationOptions"
            :selected-options="locationsCheckedOptions"
            event-name="changeLocation"
            @changeLocation="onChangeLocation"
            :numberOptions="4"
          ></filter-check>
          <filter-select
            title="Rádio de búsqueda"
            :options="radioOptions"
            :selected-option="radioCheckedOptions"
            event-name="changeRadio"
            @changeRadio="onChangeRadio"
            :numberOptions="6"
            :show-warning-info="showSelectLocationMessage"
            warning-info-text="Por favor, selecciona una localización"
          ></filter-select>
        </b-col>
        <b-col cols="12" sm="12" md="6" lg="9" :class="{'d-none': showFiltersResults }" class="d-sm-block">
          <h3>Mostrando <!--{{ totalResults }}--> resultados</h3>
          <div class="skill-badge-list mb-3">
            <skill-badge
              :id="filters.id"
              :name="filters.name"
              v-for="filters in selectedFilters"
              :key="filters.id"
              :dismisable="true"
              event-name="onRemoveFilter"
              @onRemoveFilter="onRemoveFilter"
            ></skill-badge>
          </div>
          <b-row>
            <b-col cols="12" sm="12" v-if="filteredUsers.length === 0">
              <div class="no-results-container d-flex flex-column align-items-center justify-content-around text-center mb-5">
                <img class="no-results-text" src="../assets/icons/no-results.svg" width="50">
                <p class="no-results-text">¡Vaya! Parece que no hemos encontrado ningún resultado para tu búsqueda.</p>
              </div>
            </b-col>
            <b-col v-for="user in filteredUsers" :key="user.id" cols="12" sm="12" lg="4">
              <user-filter-card :searchUserItem="user"></user-filter-card>
            </b-col>
            <b-col class="mb-5 mb-sm-4" cols="12">
              <b-pagination
                class="avify-navigator"
                v-model="currentPaginationPage"
                :total-rows="totalResults"
                :per-page="12"
                align="center"
                :hide-goto-end-buttons="true"
                prev-text="Anterior"
                next-text="Siguiente"
              ></b-pagination>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Header from '@/components/Header/Header.vue'
import FilterCheck from '@/components/FilterCheck/FilterCheck'
import UserFilterCard from '../components/Card/UserFilterCard'
import SkillBadge from '../components/SkillBadge/SkillBadge'
import LocationFactory from '@/domain/services/LocationFactory'
import FilterSelect from '../components/FilterSelect/FilterSelect'
import provincesList from '@/utils/getProvincesListUtil'
import radiosList from '@/utils/getRadioDistancesUtil'
import GooglePlaces from '../components/Places/GooglePlaces.vue'

export default {
  name: 'Finder',
  components: { FilterSelect, SkillBadge, UserFilterCard, FilterCheck, Header, GooglePlaces },
  data () {
    return {
      skillFilters: [],
      rolesFilters: [],
      sectorsFilters: [],
      languagesFilters: [],
      locationFilters: [],
      locationFilter: [],
      loading: true,
      timeout: null,
      name: null,
      radio: null,
      location: null,
      skills: null,
      showMobileFilters: false,
      showFiltersResults: false
    }
  },
  computed: {
    loggedUser () {
      return this.$store.getters.loggedUser
    },
    mobileFiltersButtonText () {
      if (this.showFiltersResults) {
        return 'Mostrar resultados'
      }

      return 'Filtra tú búsqueda'
    },
    showSelectLocationMessage () {
      const radioIsSelected = this.radioCheckedOptions !== null
      const locationIsNotSelected = this.locationsCheckedOptions.length === 0
      const isNotSelected = Object.keys(this.locationFilter).length === 0

      return (radioIsSelected && (locationIsNotSelected && isNotSelected))
    },
    skillsOptions () {
      return this.$store.getters.getSearchDataSkills
    },
    rolesOptions () {
      return this.$store.getters.getSearchDataRoles
    },
    sectorsOptions () {
      return this.$store.getters.getSearchDataSectors
    },
    languagesOptions () {
      return this.$store.getters.getSearchDataLanguages
    },
    radioOptions () {
      return radiosList.getRadios()
    },
    locationOptions () {
      return provincesList.getSpainProvinces()
    },
    skillsCheckedOptions () {
      return this.$store.getters.filterSkills
    },
    rolesCheckedOptions () {
      return this.$store.getters.filterRoles
    },
    sectorsCheckedOptions () {
      return this.$store.getters.filterSectors
    },
    languagesCheckedOptions () {
      return this.$store.getters.filterLanguages
    },
    locationsCheckedOptions () {
      return this.$store.getters.filterLocations
    },
    radioCheckedOptions () {
      return this.$store.getters.filterRadio
    },
    filteredUsers () {
      return this.$store.getters.filteredUsers
    },
    selectedFilters () {
      return this.$store.getters.selectedFilters
    },
    allSkills () {
      return this.$store.getters.getAllSkills
    },
    totalResults () {
      return this.$store.getters.totalResults
    },
    currentPaginationPage: {
      get () {
        return this.$store.getters.currentPaginationPage
      },
      set (newValue) {
        this.$store.dispatch('updateCurrentPaginationPage', newValue)
        this.onFindResults()
      }
    }
  },
  async created () {
    this.$store.dispatch('lockSendFormSaving')
    if (this.filteredUsers.length === 0) {
      this.$store.dispatch('findCommunityResults')
    }

    if (this.allSkills) {
      this.$store.dispatch('getAllSkills')
    }
    await this.$store.dispatch('getSearchData')
    this.loading = !this.loading
    this.$store.dispatch('unlockSendFormSaving')
  },
  methods: {
    filterSkills (option, label, search) {
      const cleanText = search.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
      const cleanInput = label.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
      return cleanInput.includes(cleanText)
    },
    onClickResetFilters () {
      this.name = null
      this.skills = null
      this.location = ''
      this.locationFilter = []
      this.$store.dispatch('resetFilters')
      this.$refs.googlePlaces.$el.value = ''
    },
    onChangeFilterSkills (skillData) {
      const formattedObject = Object.assign({}, {
        id: skillData.code,
        name: skillData.label
      })
      if (skillData.type === 'skill') {
        this.skillFilters.push(formattedObject)
        this.onChangeSkillOptions(this.skillFilters)
      }

      if (skillData.type === 'role') {
        this.rolesFilters.push(formattedObject)
        this.onChangeRoles(this.rolesFilters)
      }

      if (skillData.type === 'sector') {
        this.sectorsFilters.push(formattedObject)
        this.onChangeSectors(this.sectorsFilters)
      }

      if (skillData.type === 'language') {
        this.languagesFilters.push(formattedObject)
        this.onChangeLanguages(this.languagesFilters)
      }
    },
    onChangeSkillOptions (filterSkills) {
      this.skillFilters = filterSkills
      this.$store.dispatch('updateFilterSkills', filterSkills)
      this.updateFilters()
    },
    onChangeRoles (filterRoles) {
      this.rolesFilters = filterRoles
      this.$store.dispatch('updateFilterRoles', filterRoles)
      this.updateFilters()
    },
    onChangeSectors (selectedSectors) {
      this.sectorsFilters = selectedSectors
      this.$store.dispatch('updateFilterSectors', selectedSectors)
      this.updateFilters()
    },
    onChangeLanguages (selectedLanguages) {
      this.languagesFilters = selectedLanguages
      this.$store.dispatch('updateFilterLanguages', selectedLanguages)
      this.updateFilters()
    },
    onChangeName () {
      this.$store.dispatch('updateFilterName', this.name)
      this.updateFilters()
    },
    onChangeRadio (selectedRadio) {
      this.radio = selectedRadio
      this.$store.dispatch('updateFilterRadios', this.radio)
      this.updateFilters()
    },
    onChangeLocation (selectedLocation) {
      this.locationFilters = selectedLocation
      this.$store.dispatch('updateFilterLocations', selectedLocation)
      if (this.radio === null) {
        this.$store.dispatch('updateFilterRadios', 100)
      }
      this.updateFilters()
    },
    onChangeGoogleData (evt) {
      this.locationFilter = LocationFactory.getLocationByGoogle(evt)
      this.$store.dispatch('updateAlgoliaFilter', this.locationFilter)
      if (this.radio === null) {
        this.$store.dispatch('updateFilterRadios', 100)
      } else {
        this.updateFilters()
      }
    },
    onClearData () {
      this.locationFilter = []
      this.$store.dispatch('clearAlgoliaFilter')
      this.updateFilters()
    },
    updateFilters () {
      clearInterval(this.timeout)
      this.$store.dispatch(
        'updateSelectedFilters',
        [].concat(
          this.skillFilters,
          this.rolesFilters,
          this.sectorsFilters,
          this.languagesFilters,
          this.locationFilters
        )
      )
      this.timeout = setTimeout(() => { this.onFindResults() }, 1500)
    },
    onRemoveFilter (filterId) {
      this.$store.dispatch('removeSelectedFilters', filterId)
    },
    async onFindResults () {
      this.$store.dispatch('lockSendFormSaving')
      await this.$store.dispatch('findCommunityResults')
      this.skills = null
      this.$store.dispatch('unlockSendFormSaving')
    },
    showFilters () {
      this.showMobileFilters = !this.showMobileFilters
    },
    onClickShowFiltersResults () {
      window.scrollTo(0, 0)
      this.showFiltersResults = !this.showFiltersResults
    }
  }
}
</script>

<style lang="less">
  @import 'finder.less';
</style>
