export default class getRadioDistancesUtil {
  static getRadios () {
    return [
      {
        value: 15,
        text: '15 km'
      },
      {
        value: 30,
        text: '30 km'
      },
      {
        value: 60,
        text: '60 km'
      },
      {
        value: 100,
        text: '100 km'
      },
      {
        value: 200,
        text: '200 km'
      },
      {
        value: 500,
        text: '500 km'
      }
    ]
  }
}
